import { AddCircleRounded, ArticleRounded, EditRounded } from "@mui/icons-material"
import { Button, IconButton, Tooltip } from "@mui/material"
import dayjs from "dayjs"
import { pettyCashColNm } from "pages/Menu1/PettyCash/constant"
import { numberFormat } from "utils"

export const driverTripPaymentColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: 'ShowDetail',
    headerName: '',
    width: 50,
    align: 'center',
    renderCell: (params) => {
      return (
        <Tooltip title="รายละเอียดใบงาน" placement="top" followCursor>
          <IconButton size="small" sx={{ p: 0 }}>
            <ArticleRounded color="primary" />
          </IconButton>
        </Tooltip>
      )
    }
  },
  {
    field: 'AddData',
    headerName: '',
    width: 50,
    align: 'center',
    renderCell: (params) => {
      return (
        <Tooltip title="เพิ่มค่าเที่ยว" placement="top" followCursor>
          <IconButton size="small" sx={{ p: 0 }}>
            <AddCircleRounded color="primary" />
          </IconButton>
        </Tooltip>
      )
    }
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "วันที่นัด",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "FinDte",
    headerName: "วันที่ลงจบ",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "JobSNm",
    headerName: "ชื่องาน",
    width: 150,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({ row: { TakePlc, Loc, RtnPlc } }) => `${TakePlc}-${Loc}-${RtnPlc}`,
    width: 250,
  },
  {
    field: "Sts1NNm",
    headerName: "พนักงานรับ",
    width: 100,
    cellClassName: ({ row }) => !row.Sts1DrvId ? "disabled" : null,
  },
  {
    field: `E92`,
    headerName: "E92",
    width: 100,
    align: 'right',
    editable:true,
    type: "number",
    cellClassName: ({ row }) => !row.Sts1DrvId ? "disabled" : null,
    valueGetter: ({ row, value }) => row.Sts1DrvId ? value : "",
    valueFormatter: ({ value }) => value === null || value === undefined ? '-' : value === "" ? "" : numberFormat(value)
  },
  {
    field: "Sts3NNm",
    headerName: "พนักงานส่ง",
    width: 100,
    cellClassName: ({ row }) => !row.Sts3DrvId ? "disabled" : null,
  },
  {
    field: `E88`,
    headerName: "E88",
    width: 100,
    align: 'right',
    editable:true,
    type: "number",
    cellClassName: ({ row }) => !row.Sts3DrvId ? "disabled" : null,
    valueGetter: ({ row, value }) => row.Sts3DrvId ? value : "",
    valueFormatter: ({ value }) => value === null || value === undefined ? '-' : value === "" ? "" : numberFormat(value)
  },
  {
    field: "Sts5NNm",
    headerName: "พนักงานคืน",
    width: 100,
    cellClassName: ({ row }) => !row.Sts5DrvId ? "disabled" : null,
  },
  {
    field: `E93`,
    headerName: "E93",
    width: 100,
    align: 'right',
    editable:true,
    type: "number",
    cellClassName: ({ row }) => !row.Sts5DrvId ? "disabled" : null,
    valueGetter: ({ row, value }) => row.Sts5DrvId ? value : "",
    valueFormatter: ({ value }) => value === null || value === undefined ? '-' : value === "" ? "" : numberFormat(value)
  },
  {
    field: "OthExp",
    headerName: "ค่าอื่นๆ",
    minWith: 150,
    flex: 1
  },
  {
    field: "SalDte",
    headerName: "คิดเงินดือน",
    width: 110,
    // valueFormatter: ({ value }) => value ? dayjs(value).format("MM/YYYY") : '',
    renderCell: ({ value }) => (
      <Button size="small" variant={value ? "outlined" : "contained"}>
        {value ? dayjs(value).format("MMMM YYYY") : "กำหนดเดือน"}
      </Button>
    )
  },
]

export const otherDriverTripColumns = [

  {
    field: 'ShowDetail',
    headerName: '',
    width: 50,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" sx={{ p: 0 }}>
          <EditRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "UsrAccNm",
    headerName: pettyCashColNm.UsrAccId,
    minWidth: 120,
    flex: 1
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    minWidth: 120,
    flex: 1
  },
  {
    field: `Amnt`,
    headerName: pettyCashColNm.Amnt,
    width: 100,
    align: 'right',
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value === null || value === undefined ? '-' : numberFormat(value)
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    minWidth: 120,
    flex: 1
  },
]