import { FolderOpenRounded, SystemUpdateAltRounded } from "@mui/icons-material"
import { Box, Button, Dialog, DialogContent, Divider, TextField, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { modifyPOICombo } from "branch/functions/modifyPOICombo"
import { BoxFC, BoxFR } from "components/BoxCustom"
import ComboBox from "components/ComboBox"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { shipmentApiNm, shipmentColNm, initDialogData, } from "constants/shipmentConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import isFileOverSize from "utils/isFileOverSize"

const initReadFileDialogData = {
  ...initDialogData,
  Vol: [],
  VolStr: "",
  ShpmTyp: "EX",
}
const ReadFileDialog = ({ dialogOpen, setDialogOpen, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initReadFileDialogData })
  const [fileName, setFileName] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)
  const [fileUrl, setFileUrl] = useState(null);

  const shpmTypId = useMemo(() => {
    return dialogData.ShpmTyp === "EX" ? 1 : 2
  }, [dialogData.ShpmTyp])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    label: shipmentColNm[name],
    value: dialogData[name],
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value }))
  }), [dialogData])

  const dateTimePickerProp = useCallback((name) => ({
    label: shipmentColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} />,
  }), [dialogData])

  const handleSelectFile = useCallback((e) => {
    const file = e.target.files[0]
    if (isFileOverSize(file)) return

    setSelectedFile(file)
    setFileName(file.name)
    // const pdfFiles = data.Files
    // .filter(file => file.ShpFileTypId === 2)
    // .map(file => `/file/shipping/${nowShpInvId}/${file.FileNm}`)
    // setPdfData(pdfFiles)

  }, [])

  const handleUploadFile = useCallback(async () => {
    const data = new FormData();
    data.append("file", selectedFile);
    // const result = await ax.post(shipmentApiNm.uploadReadFile, data)


    ax.post(shipmentApiNm.uploadReadFile, data).then(value => {
      if (value.data) {
        setDialogData(value.data)
        // setDialogData({
        //   ...value.data,
        //   VolStr: Array.isArray(value.data.Vol) ? value.data.Vol.join(", ") : "N/A"
        // })
      }
    })
  }, [ax, selectedFile])

  /**
   * have to remove TakePlc and RtnPlc coz it has proble when set TakePOIId to null old code will show TakePlc
   */
  const handleDialogOk = useCallback(() => {
    onFinish({ 
      ...dialogData, 
      TakePlc: "",
      RtnPlc:"",
      TakePlcAI: dialogData.TakePlc,
      RtnPlcAI: dialogData.RtnPlc,
      file: selectedFile })
    setDialogOpen(false)
  }, [onFinish, setDialogOpen, dialogData, selectedFile])


  const handleDragOver = useCallback((e) => {
    /** Must prevent the can use onDrop */
    e.preventDefault()
  }, [])
  const handleDropFile = useCallback((e) => {
    
    e.preventDefault()
    let files = []
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file') {
        files = Array.from(e.dataTransfer.items).map(item => item.getAsFile())
      } else {
        return
      }
    } else {
      if (e.dataTransfer.files.length > 0) {
        files = Array.from(e.dataTransfer.files)
      } else {
        return
      }
    }

    for (const file of files) {
      if (isFileOverSize(file)) return
      
      // const fileNameOnly = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
      file.label = file.name
      file.imgLink= URL.createObjectURL(file)
    }
    
    setFileName(files[0].name)
    setSelectedFile(files[0])
  }, [setSelectedFile])

  
  const handlePaste = useCallback((e) => {
    const items = e.clipboardData.items;
    for (const item of items) {
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        // setFileData(file);
        setSelectedFile(file);
        // setPreviewSrc(URL.createObjectURL(file));
        break;
      }
    }
  }, []);
  
  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);

  useEffect(() => {
    if (!dialogOpen) {
      setFileName("")
      setSelectedFile(null)
      setDialogData({ ...initReadFileDialogData })
    }
  }, [dialogOpen, ax])

  /**
   * prevent preview blink if loaded pdf
   */
  useEffect(() => {
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setFileUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [selectedFile]);

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="อ่านข้อมูลจากไฟล์"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR pt={2} height={730} sx={{ alignItems: "stretch" }}>
          <BoxFC width={380} sx={{ gap: 1 }}>
            <BoxFR>
              <TextField size="small" label="ชื่อไฟล์" fullWidth value={fileName} />
              <Button variant="contained" component="label">
                <input type="file" hidden onChange={handleSelectFile} />
                <FolderOpenRounded />
              </Button>
            </BoxFR>
            <Button size="small" variant="contained" fullWidth onClick={handleUploadFile}>ดึงข้อมูล</Button>
            <Divider />
            <TextField {...textFieldProps("Bkg")} />
            <TextField {...textFieldProps("Agnt")} />
            <TextField {...textFieldProps("Vssl")} />
            <TextField {...textFieldProps("Voy")} />
            <TextField {...textFieldProps("Port")} />
            <DateTimePickerCustomTime {...dateTimePickerProp("ETD")} />
            <DateTimePickerCustomTime {...dateTimePickerProp("ETA")} />
            <BoxFR sx={{ gap: 1 }}>
              <TextField {...textFieldProps("TakePlc")} />

              <ComboBox sx={{ width: 180 }}
                options={modifyPOICombo(msData.poiComboWithAdd, "TAKE", shpmTypId)}
                label={shipmentColNm.TakePlc}
                selectedId={dialogData.TakePOIId}
                setSelectedId={(id) => { setDialogData(o => ({ ...o, TakePOIId: id })) }} />
            </BoxFR>
            <DateTimePickerCustomTime {...dateTimePickerProp("TakeDteSt")} />
            <BoxFR>

              <TextField {...textFieldProps("RtnPlc")} />
              <ComboBox sx={{ width: 180 }}
                options={modifyPOICombo(msData.poiComboWithAdd, "RTN", shpmTypId)}
                label={shipmentColNm.RtnPlc}
                selectedId={dialogData.RtnPOIId}
                setSelectedId={(id) => { setDialogData(o => ({ ...o, RtnPOIId: id })) }} />
            </BoxFR>
            <DateTimePickerCustomTime {...dateTimePickerProp("RtnDteSt")} />
            <DateTimePickerCustomTime {...dateTimePickerProp("RtnDteEn")} />
            <TextField {...textFieldProps("VolStr")} label="Volume" />
          </BoxFC>
          <BoxFC sx={{ gap: 0 }} width={700} height="100%" p={1} border="1px solid lightgrey" 
          borderRadius={2} bgcolor="white" onDrop={handleDropFile} onDragOver={handleDragOver}>
            {selectedFile ? (
              <Box
                component="iframe"
                src={fileUrl}
                width="100%"
                height="100%"
              //onLoad={() => URL.revokeObjectURL(selectedFile)}

              />
            ) : (
              <BoxFC sx={{ gap: 0 }} justifyContent="center" alignItems="center" width="100%" height="100%">
                <SystemUpdateAltRounded sx={{ fontSize: 50, color: grey[500] }} />
                <Typography variant="h6" sx={{ color: grey[500] }}>Drop file here</Typography>
                <Typography variant="h6" sx={{ color: grey[500] }}>Or</Typography>
                <Typography variant="h6" sx={{ color: grey[500] }}>Paste image from clipboard</Typography>
              </BoxFC>
            )}
          </BoxFC>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText={"ใส่ข้อมูลในใบแจ้งลาก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(ReadFileDialog)